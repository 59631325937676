import FetchWrapper from './fetch-wrapper.js';
const thisWebsiteAPI = new FetchWrapper(`${window.location.protocol}//${window.location.host}` );

// Add a class "js-scrolled" both on scroll and if the page is scrolled on load (e.g, on a refresh)
function windowHasScrolled() {
	const html = document.querySelector('html');

	if (window.scrollY > 0) {
		html.classList.add('js-scrolled');
	} else {
		html.classList.remove('js-scrolled');
	}
}

// Indicate to users that Sprig forms are doing something when submitted
document.querySelectorAll('form[sprig]').forEach(sprigForm => {
	sprigForm.insertAdjacentHTML('beforeend', `
		<dialog class="modal sprigActionFeedback">
			<p>Please wait</p>
			<button>Close</button>
		</dialog>
	`);

	let dialog = sprigForm.querySelector('.sprigActionFeedback');

	dialog.querySelector('button').addEventListener('click', () => {
		dialog.close();
	});

	sprigForm.addEventListener('submit', () => {
		dialog.showModal();
	});
});

/**
 * Returns what type of interaction mechanism the current device uses
 * @returns {string}
 */
export function interactionType() {
	let interactionType = 'mouseover';

	if( window.matchMedia('(hover: hover)') ) { // desktop
		interactionType = 'mouseover'; }
	if( window.matchMedia('(hover: none) and (pointer: coarse)') ) { // touchscreen
		interactionType = 'click'; }
	if( window.matchMedia('(hover: none) and (pointer: fine)') ) { // stylus
		interactionType = 'click'; }
	if( window.matchMedia('(hover: hover) and (pointer: coarse)') ) { // Wii/Kinect/etc
		interactionType = 'mouseover'; }
	if( window.matchMedia('(hover: hover) and (pointer: fine)') ) { // mouse
		interactionType = 'mouseover'; }

	return interactionType;
}

/**
 * Adds an event listener which will indicate if the page has scrolled by manipulating a class on the HTML element.
 */
export function initWindowHasScrolled() {
	window.addEventListener('scroll', function() {
		windowHasScrolled();
	});
}

export function timelineChange() {
	document.querySelectorAll('.period').forEach(period => {
		period.addEventListener('click', (e) => {
			e.preventDefault();
			let clickedLink = e.currentTarget;
			let parser = new DOMParser();
			console.log(clickedLink);
			clickedLink.classList.toggle('open');
		});
	});
}

/**
 * Enable animated "intro" on blocks controlled by CSS when elements scroll into view
 */
export function initAnimatedIntroBlocks() {
	if (!!window.IntersectionObserver) {
		document.querySelector('html').classList.add('js-supportsIntersectionObserver');

		let observer = new IntersectionObserver((watchList, observer) => {
			watchList.forEach(watchedElement => {
				if (watchedElement.isIntersecting) {
					// console.log(watchedElement);

					watchedElement.target.classList.add('js-inViewport');
					observer.unobserve(watchedElement.target);
				}
			});
		}, {rootMargin: "0px 0px -20% 0px"});

		document.querySelectorAll('[data-scroll-reveal]').forEach(watchTarget => {
			observer.observe(watchTarget);
		});
	}
}

/**
 * Handle anchors with a class of popup
 */
export function initPopups() {
	document.querySelectorAll('a.popup').forEach(popupLink => {
		popupLink.addEventListener('click', (e) => {
			e.preventDefault();
			let clickedLink = e.currentTarget;
			let parser = new DOMParser();
			clickedLink.classList.add('js-loading');

			thisWebsiteAPI.getHtml(clickedLink.getAttribute('href')).then(response => {
				let responseAsDom = parser.parseFromString(response, "text/html");
				let imageWeWant = responseAsDom.querySelector('#ajaxcontent').outerHTML;
				let lightbox = document.querySelector('#lightbox') ?? null;

				if (lightbox) {
					document.querySelector('#lightbox .content').innerHTML = imageWeWant;
					lightbox.showModal();
					clickedLink.classList.remove('js-loading');
				} else {
					document.querySelector('body').insertAdjacentHTML('afterbegin', `
					<dialog id="lightbox">
						<div class="wrapper">
							<div class="content">
							${imageWeWant}
							</div>
							<form method="dialog">
								<button><img src="/dist/svg/x.svg" alt="Close"></button>
							</form>
						</div>
					</dialog>
				`);

					let lightbox = document.querySelector('#lightbox');
					lightbox.showModal();
					clickedLink.classList.remove('js-loading');
				}
			}).catch(error => {
				console.error(error);
			});

			console.log(clickedLink);
		});
	});

	document.querySelectorAll('a.popup-video').forEach(popupLink => {
		popupLink.addEventListener('click', (e) => {
			e.preventDefault();
			let clickedLink = e.currentTarget;
			let clickedUrl = new URL(clickedLink.href);
			let videoId = clickedUrl.searchParams.get('v');
			let videoWeWant = `<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/${videoId}" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;

			clickedLink.classList.add('js-loading');

			document.querySelector('body').insertAdjacentHTML('afterbegin', `
			<dialog id="lightbox">
				<div class="wrapper">
					<div class="content">
						${videoWeWant}
					</div>
					<form method="dialog">
						<button><img src="/dist/svg/x.svg" alt="Close"></button>
					</form>
				</div>
			</dialog>
		`);

			let lightbox = document.querySelector('#lightbox') ?? null;
			let theVideo = document.querySelector('#lightbox iframe');

			lightbox.showModal();
			clickedLink.classList.remove('js-loading');

			// remove the entire thing from the DOM when closed, to stop the video from continuing to play
			document.querySelector('#lightbox').addEventListener("close", e => {
				e.target.remove();
			});
		});
	});
}

/**
 * Adds a Dismiss button and behaviour to CMS generated Flash messages
 */
export function dismissNotice() {
	let theNotice = document.querySelector("#notice:not(.cart)");

	if (theNotice) {
		theNotice.insertAdjacentHTML('beforeend', `<button role=button class="uc_button">Dismiss</button>`);
		theNotice.querySelector('button').addEventListener('click', e => {
			e.preventDefault();
			theNotice.remove();
		});
	}
}

/**
 * Initiate the VAT toggle behaviour.
 * Inserts a toggle button, and handles all applicable prices.
 */
export function initVAT() {
	let currentPreference = getPreferredVAT();

	/* Get the nav item we'll punt the dynamic toggle button before */
	const changeState = document.querySelector('.nav_secondary');

	let buttonToggle = `
		<li>
			<button role="button" data-vat="${ currentPreference }" id="vatToggle">Include VAT</button>
			<div class="tooltip"><span>${ currentPreference == 'exVat' ? 'Excluding' : 'Including' }</span> VAT in prices shown for products on the site.</div>
		</li>
	`;
	changeState.insertAdjacentHTML('beforeend', buttonToggle);

	/* Add the toggle button and assign the toggle function to it */
	let button = document.querySelector('#vatToggle');
	button.addEventListener('click', toggleVAT);

	/* update all the prices on the page */
	updateAllPrices( currentPreference );
}

/**
 * Updates all HTML nodes with a [data-raw-price] attribute to reflect the price preference of the visitor.
 * .i.e., including or excluding VAT
 * @param mode
 */
export function updateAllPrices( mode ) {
	const toChange = document.querySelectorAll("[data-raw-price]");
	const vatRate = parseFloat( document.querySelector('html').dataset.vatRate ) + 1;

	toChange.forEach( item => {
		let newPrice = item.dataset.rawPrice;
		let message = `(ex VAT)`;

		if ( mode == 'incVat' ) {
			newPrice = item.dataset.rawPrice * vatRate;
			message = `(inc VAT)`;
		}

		let formattedPrice = new Intl.NumberFormat(`en-GB`, {
			currency: `GBP`,
			style: 'currency',
		}).format(newPrice);

		item.textContent = `${formattedPrice} ${message}`;
	});
}

/**
 * Respond to clicking the toggle VAT button.
 * Updates the button state, sets the localStorage value, updates all prices on the page, updates the toggle tooltip.
 * @param event
 */
export function toggleVAT( event ) {
	if ( event.currentTarget.dataset.vat == 'exVat' ) {
		event.currentTarget.dataset.vat = 'incVat'; // updates the button state
		setPreferredVAT('incVat');
		updateAllPrices( 'incVat' );
		document.querySelector('#vatToggle + .tooltip span').textContent = 'Including';
	}
	else {
		event.currentTarget.dataset.vat = 'exVat';
		setPreferredVAT('exVat');
		updateAllPrices( 'exVat' );
		document.querySelector('#vatToggle + .tooltip span').textContent = 'Excluding';
	}
}

/**
 * Returns value of `vatPreference` from localStorage, and sets it as `exVat` if not set.
 * @returns {string}
 */
export function getPreferredVAT() {
	let storedValue = localStorage.getItem("vatPreference");

	if (storedValue) {
		return storedValue;
	}
	else {
		setPreferredVAT("exVat");
		return "exVat";
	}
}

/**
 * Sets the value of `vatPreference` in localStorage
 * @param {string} value - incVat or exVat
 */
export function setPreferredVAT(value) {
	localStorage.setItem("vatPreference", value);
}

/**
 * Adds a "Just in" label to products that were added within the last ten days.
 * This is client-side calculated to allow efficient caching server side
 */
export function justIn() {
	const utcDate = new Date();
	const isoDate = utcDate.toISOString();
	document
		.querySelectorAll('.Product[data-date-added]')
		.forEach(product => {
			const prodDate = new Date(product.dataset.dateAdded);
			const dateInUTC = new Date(isoDate);

			let differenceInDays = Math.abs(dateInUTC.getDate() - prodDate.getDate());

			if (differenceInDays < 10) {
				product
					.querySelector(':scope .flashMessages')
					.insertAdjacentHTML(
						'beforeend',
						`<li class="uc_flashMessage">Just In</li>`
					);
			}
		});
}
